import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Outlet } from '../../../../core/models';
import { CartRuleGroupedStatus, MinimalCartRuleGroupedStatus } from '../../../../store/cart/cart.models';
import { RecommendOrderBonus } from '@Mesh/store/order/order.model';

@Component({
  selector: 'app-basket-total-info',
  templateUrl: './basket-total-info.component.html',
  styleUrls: ['./basket-total-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketTotalInfoComponent implements OnInit {
  @Input() outlet: Outlet;
  @Input() totalOrderPrice: number;
  @Input() totalAmountOfOrder: number;
  @Input() totalOrderDiscountPrice: number;
  @Input() minimalCartRulesGroupStatus: MinimalCartRuleGroupedStatus;
  @Input() nextDeliveryDate: string;
  @Input() recommendedOrder: RecommendOrderBonus;
  @Input() recommendedOrderActive: boolean;
  @Output() ordered = new EventEmitter<any>();
  @Output() activeChanges = new EventEmitter<{ active: boolean; recommendedOrderId: number }>();
  @Output() updatedBasketToOrder = new EventEmitter<any>();
  CartRuleGroupedStatus = CartRuleGroupedStatus;
  today = new Date();

  constructor() {}

  ngOnInit(): void {}

  onOrder(): void {
    this.ordered.emit();
  }

  onActiveRecommendedOrder(event: { active: boolean; recommendedOrderId: number }): void {
    this.activeChanges.emit(event);
  }

  onUpdatedBasketToOrder(event: number): void {
    this.updatedBasketToOrder.emit(event);
  }

  get hasDiscount() {
    return this.totalOrderPrice !== this.totalOrderDiscountPrice;
  }
}
