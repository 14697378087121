import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataService } from '@Mesh/core/services';
import { Outlet, User } from '@Mesh/core/models';
import { AppState } from '@Mesh/store/app.state';
import { select, Store } from '@ngrx/store';

import { IProductParamCategory, ProductTableService } from '@Mesh/core/services/product-table.service';
import { ResizeHelper } from '@Mesh/shared/helpers/resize.helper';
import { CatalogHelper } from '@Mesh/shared/helpers/catalog.helper';
import { BasketHelper } from '@Mesh/shared/helpers/basket.helper';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { map, take, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { ScrollService } from '@Mesh/pages/catalog/services/scroll.service';
import { CartActions, CartSelectors } from '@Mesh/store/cart';
import { bonusSelectors } from '@Mesh/store/bonus';
import { CatalogPageTypes } from '@Mesh/pages/catalog/catalog.routing';
import { nullOrUndefined } from '@Mesh/utils/helpers';
import { CartItem, CartRuleGroupedStatus, MinimalCartRuleGroupedStatus } from '@Mesh/store/cart/cart.models';
import {
  LoadMinimalCartRuleGroupedStatus,
  LoadMinimalCartRulesByCategory,
  ResetMinimalCartRulesByCategory,
} from '../../../store/cart/cart.actions';
import {
  LoadBonusesRecommendedOrders,
  LoadBonusesRecommendedOrdersByFilters,
  LoadBonusesRecommendedOrdersWithoutFilters,
  ResetBonusesRecommendedOrdersByFilters,
} from '../../../store/order/order.actions';
import { orderSelectors } from '../../../store/order/order.selectors';

interface PageInfo {
  offset: number;
  pageSize: number;
  limit: number;
  count: number;
}

@Component({
  selector: 'app-catalog-list',
  templateUrl: './catalog-list.component.html',
  styleUrls: ['./catalog-list.component.scss'],
  animations: [
    trigger('basketButtonOpenClose', [
      state(
        'open',
        style({
          opacity: '*',
          bottom: '15px',
        })
      ),
      state(
        'closed',
        style({
          opacity: 0,
          bottom: '-56px',
        })
      ),
      transition('open => closed', [animate('0.7s')]),
      transition('closed => open', [animate('0.5s')]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('catalogFiltersComponent') catalogFiltersComponent: ElementRef;

  user: User;
  basketToggle = false;
  minimalCartRulesGroupStatus$: Observable<MinimalCartRuleGroupedStatus>;
  CartRuleGroupedStatus = CartRuleGroupedStatus;

  readonly selectedFilters$ = new BehaviorSubject<{ filterId: string; filterName: string; value: string }[]>([]);
  readonly cart$ = this.store.select(CartSelectors.selectProductsForCartDisplay);
  readonly totalCartItems$ = this.store.select(CartSelectors.numberOfCartItems);
  readonly totalAmountOfOrder$ = this.store.select(CartSelectors.totalAmountOfOrder);
  readonly totalOrderPrice$ = this.store.select(CartSelectors.selectTotalPriceOfOrder);
  readonly activeBonusId$ = this.store.select(bonusSelectors.getActiveBonusId);
  // readonly bonusRecommendedOrdersByCatalog$ = this.store.select(orderSelectors.getBonusRecommendedOrdersByCatalog);
  readonly hideMobileFilters$ = combineLatest([this.scrollService.mobileHeaderExpanded$, this.selectedFilters$]).pipe(
    map(([expanded, selectedFilters]) => !expanded && !!selectedFilters?.length)
  );

  readonly hideDesktopFilters$ = combineLatest([this.scrollService.desktopHeaderExpanded$, this.selectedFilters$]).pipe(
    map(([expanded, selectedFilters]) => !expanded && !!selectedFilters?.length)
  );
  readonly minimalOrderProductCategory$ = this.store.select(CartSelectors.selectMinimalCartRules);

  readonly nextDeliveryDate$ = this.store.select(CartSelectors.nextDeliveryDate);
  today = new Date();

  readonly filtersRemove$ = new Subject<boolean>();

  protected unsubscribe$ = new Subject();

  private pageType$ = this.route.data.pipe(
    map((data) => {
      return data.pageType as CatalogPageTypes;
    })
  );
  paramsFilters: IProductParamCategory;

  constructor(
    public dataService: DataService,
    public store: Store<AppState>,
    public productTableService: ProductTableService,
    public resize: ResizeHelper,
    public catalogHelper: CatalogHelper,
    public basket: BasketHelper,
    public route: ActivatedRoute,
    public router: Router,
    public scrollService: ScrollService
  ) {}

  ngOnInit() {
    document.body.style.overflowY = 'scroll';
    document.body.style.minHeight = 'calc(100vh + 30px)';
    // this.store.dispatch(new CartActions.LoadCart());
    this.store.dispatch(new LoadBonusesRecommendedOrders({ executed: false, predict: true }));
    this.minimalCartRulesGroupStatus$ = this.store.pipe(select(CartSelectors.selectMinimalCartRulesGroupStatus));
    // this.store.pipe(select(CartSelectors.selectCart), takeUntil(this.unsubscribe$)).subscribe((cartList) => {
    //   this.getMinimalRules(cartList);
    // });
    this.hideDesktopFilters$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        setTimeout(() => {
          const element = this.catalogFiltersComponent?.nativeElement;
          const headerOffset = 80;
          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }, 150);
      }
    });
  }

  ngAfterViewInit(): void {
    this.resize.recalcSize();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    document.body.style.minHeight = 'auto';
  }

  @HostListener('window:resize', [])
  caller() {
    this.resize.recalcSize();
  }

  onChangeOutlet(outlet: Outlet): void {
    this.store.dispatch(new CartActions.ResetCart());
    this.store.dispatch(new CartActions.LoadCart());
  }

  getProduct(params: Partial<IProductParamCategory> | { planId: string }, changeFilters = true) {
    this.pageType$.pipe(take(1)).subscribe((pageType) => {
      const newParams = { ...params };
      const filterParam = Object.entries({
        ...this.productTableService.queryParam.value,
        ...newParams,
      }).reduce((a, [k, v]) => (v === null ? a : ((a[k] = v), a)), {}) as IProductParamCategory;
      this.getMinimalOrders(filterParam);
      // this.getRecommendedOrders(filterParam);
      const catalogPage = 0; // CatalogPageTypes.Catalog
      if (pageType === catalogPage && !nullOrUndefined(filterParam.planId)) {
        delete filterParam.planId;
      }
      // console.log(filterParam);
      this.productTableService.setQuery(filterParam);
    });
  }

  @HostListener('document:scroll', ['$event'])
  onScroll() {
    this.scrollService.onScroll();
  }

  setFilters(arr) {
    this.selectedFilters$.next(arr);
  }

  setSort(arr) {
    this.selectedFilters$.next(arr);
  }

  removeFilters() {
    this.filtersRemove$.next(true);
    this.scrollService.scrollTop();
  }

  getMinimalOrders(params: IProductParamCategory) {
    if (params.materialGroupNameId) {
      this.paramsFilters = params;
      this.store.dispatch(new LoadMinimalCartRulesByCategory({ materialGroupNameId: params.materialGroupNameId }));
    } else {
      this.paramsFilters = null;
      this.store.dispatch(new ResetMinimalCartRulesByCategory());
    }
  }

  getRecommendedOrders(params: IProductParamCategory) {
    if (params.materialGroupNameId) {
      const data = { ...params, executed: false, predict: true };
      this.store.dispatch(new LoadBonusesRecommendedOrdersByFilters(data));
    } else {
      this.store.dispatch(new LoadBonusesRecommendedOrdersWithoutFilters({ executed: false, predict: true }));
    }
  }

  getMinimalRules(cartList: CartItem[]) {
    if (cartList && cartList.length) {
      this.store.dispatch(new LoadMinimalCartRuleGroupedStatus());
      if (this.paramsFilters) {
        this.store.dispatch(new LoadMinimalCartRulesByCategory({ materialGroupNameId: this.paramsFilters.materialGroupNameId }));
      } else {
        this.store.dispatch(new LoadMinimalCartRulesByCategory());
      }
    } else {
      this.store.dispatch(new ResetMinimalCartRulesByCategory());
    }
  }

  onShowAutoOrder(): void {
    const queryParams = {
      action: 'basket',
      action_type: 'catalog',
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  onShowBasket(): void {
    const queryParams = {
      action: 'basket',
      action_type: 'default',
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
    });
    this.store.dispatch(new CartActions.LoadCart());
  }
}
