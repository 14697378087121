import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {
  CartItem,
  DeleteCartItem,
  DeleteCartItemCriteria,
  LoadCartCriteria,
  MinimalCartRuleGroupedStatus,
  MinimalCartRules,
  MinimalCartRulesCriteria,
  UpdateCartItem,
  OrderCompleted,
  CartCategoryTreeCriteria,
  CartFileAddressProcess,
} from '@Mesh/store/cart/cart.models';
import { API_PLAN_URL } from '@Env/environment';
import { objectToParams } from '../../../shared/helpers/object.helpers';

@Injectable({ providedIn: 'root' })
export class CartService {
  url = API_PLAN_URL;

  constructor(private httpClient: HttpClient) {}

  loadCart(addressSapId: number, criteria: LoadCartCriteria): Observable<CartItem[]> {
    const params = objectToParams(criteria);
    return this.httpClient.get<CartItem[]>(`${this.url}/v1/cart/${addressSapId}`, { params });
  }

  // submitCart(addressSapId: number, remark: string, userId: number, recommendedOrderId?: number): Observable<{ orderNumber: string }> {
  //   return this.httpClient.post<{ orderNumber: string }>(`${this.url}/v1/cart/${addressSapId}/submit`, {
  //     remark,
  //     userId,
  //     recommendedOrderId,
  //   });
  // }

  submitCart(
    addressSapId: number,
    remark: string,
    userId: number,
    cartSum: number,
    shouldRequestInvoice: boolean,
    recommendedOrderId?: number,
    recommendedWithOthers?: boolean
  ): Observable<OrderCompleted[]> {
    return this.httpClient.post<OrderCompleted[]>(`${this.url}/v2/cart/${addressSapId}/submit`, {
      remark,
      userId,
      recommendedOrderId,
      cartSum,
      shouldRequestInvoice,
      recommendedWithOthers,
    });
  }

  updateCart(addressSapId: number, body: UpdateCartItem): Observable<CartItem> {
    return this.httpClient.put<CartItem>(`${this.url}/v1/cart/${addressSapId}`, body);
  }

  deleteProduct(addressSapId: number, body: DeleteCartItemCriteria): Observable<void> {
    return this.httpClient.request<void>('delete', `${this.url}/v1/cart/${addressSapId}`, { body });
  }

  updateRecommendedOrderCart(addressSapId: number, recommendedOrderId: number, payload: { userId: number }): Observable<CartItem[]> {
    return this.httpClient.put<CartItem[]>(`${this.url}/v1/cart/${addressSapId}/${recommendedOrderId}`, payload);
  }

  getClosestDeliveryDate(addressSapId: number): Observable<{ deliveryDate: string }> {
    return this.httpClient.get<{ deliveryDate: string }>(`${this.url}/v1/delivery-date/${addressSapId}/nearest`);
  }

  getMinimalCartRulesByCategory(addressSapId: number, criteria: MinimalCartRulesCriteria): Observable<MinimalCartRules[]> {
    const params = objectToParams(criteria);
    return this.httpClient.get<MinimalCartRules[]>(`${this.url}/v1/minimal-cart-rule/by-category/${addressSapId}`, { params });
  }

  getMinimalCartRuleGroupedStatus(addressSapId: number, criteria: MinimalCartRulesCriteria): Observable<MinimalCartRuleGroupedStatus> {
    const params = objectToParams(criteria);
    return this.httpClient.get<MinimalCartRuleGroupedStatus>(`${this.url}/v1/minimal-cart-rule/status/${addressSapId}`, { params });
  }

  fillUpCartsMinimalLimit(addressSapId: number, criteria: { userId: number }): Observable<any> {
    const params = objectToParams(criteria);
    return this.httpClient.post<any>(`${this.url}/v1/cart/${addressSapId}/fill-up-to-minimal`, { id: null }, { params });
  }

  getCartCategoryTree(criteria: CartCategoryTreeCriteria): Observable<any> {
    const params = objectToParams(criteria);
    return this.httpClient.get<any>(`${this.url}/v2/category/cart/tree`, { params });
  }

  getProgressFillCartByFile(filePath: string): Observable<CartFileAddressProcess> {
    return this.httpClient.post<CartFileAddressProcess>(`${this.url}/v1/cart-file/process`, {}, { params: { filePath } });
  }

  getFillCartByFileCurrentLoaded(criteria: { addressSapId: number }): Observable<CartFileAddressProcess> {
    const params = objectToParams(criteria);
    return this.httpClient.get<CartFileAddressProcess>(`${this.url}/v1/cart-file/current`, { params });
  }
}
